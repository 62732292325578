<template>
  <div class="consult">
    <van-tabs v-model="active" class="van_tab" @change="onChange">
      <van-tab title="全部" name=""></van-tab>
      <van-tab title="图文问诊" name="1"></van-tab>
      <van-tab title="电话问诊" name="2"></van-tab>
    </van-tabs>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="list" class="van_list">
      <div v-for="item in docList" :key="item.id">
        <div class="new_list" @click="onFastDetail(item)" v-if="item.orderStatus != -1">
          <div class="infos">
            <img v-if="item.doctorImage" :src="item.doctorImage" alt="" class="avatar">
            <div class="t1">{{item.doctorName ? item.doctorName : '快速问诊'}}</div>
            <div class="t2">{{item.doctorClinic}}</div>
            
            <div v-if="item.isActivity" class="t3">{{item.activityName}}</div>
            <div v-else class="t3">{{item.consultType == 1 ? '图文' : '电话'}}-{{item.templateType == 1 ? '快速问诊' : '定向问诊'}}</div>
            <div class="spot" v-if="item.unreadCountMember"></div>
          </div>
          <div class="cont">
            <div class="t1">主诉：{{item.questionContent}}</div>
            <div class="t2">{{item.createTime}}</div>
          </div>
          <div class="btns">
            <div class="t1" v-if="item.consultType == 1">{{formatOrderStatus(item.orderStatus)}}</div>
            <div class="t1" v-if="item.consultType == 2">{{formatServiceOrderStatus(item.serviceOrderStatus)}}</div>
            <div>
              <button class="butn y" v-if="item.orderStatus != 5 && item.orderPayStatus == 1">待支付</button>
              <button class="butn" v-if="item.orderStatus == 4 && item.isEstimate == 1">待评价</button>
              <!-- <button class="butn n_r">药品订单</button> -->
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import bus from '../utils/bus';
import BASE_URL from '@/request/base';
// import { parse } from 'express/node_modules/@types/qs';
export default {
  data() {
    return {
      active: 0,
      docList: [],
      url: BASE_URL.devUrl,
      socket: null,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      active: ''
    }
  },

  created() {
    this.$store.dispatch('setPrice');
    this.watchSocket();
    // console.log('路由==》', this.$route)

  },
  methods: {
    // 监听socket信息接收通知   sessionType: // 1回执通知 2会话 3订单通知 4心跳 5关闭 6通知用户已接单 7转单通知 8用户离线通知
    watchSocket() {
      this.$socket.onMessage(data => {
        // console.log(data);
        if (data.sessionType == 2) {  //显示未读红点
          if (this.$route.path != '/consult') {  //当前页面不显示tabbar红点
            bus.$emit('showSpot') //通知tabbar显示红点
          }
          for (let i = 0; i < this.docList.length; i++) {
            if (this.docList[i].orderId == data.consultOrderId) {
              this.docList[i].unreadCountMember += 1;
            }
          }
        } else if (data.sessionType == 6 || data.sessionType == 5) {
          this.pageSize = 10;
          this.pageNum = 1;
          this.docList = [];
          this.list();
        }
      })
    },
    // 切换
    onChange() {
      this.pageNum = 1;
      this.docList = [];
      this.list();
    },
    list() {
      this.loading = true;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let params = {
        memberId: userData.memberId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        consultType: this.active
      }
      let _this = this;
      _this.$http('get', '/server/order/getOnlineDoctorInfoPageList', params, 1).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          // console.log('res=====>', res);
          if (res.data.code == 200) {
            _this.docList = _this.docList.concat(res.data.rows);
            if (res.data.rows.length) {
              _this.pageNum += 1;
              _this.loading = false;
            } else {
              _this.finished = true;
            }
            _this.docList.forEach(item => {
              if (item.newMessge) {
                if ((item.newMessge.indexOf('.mp3') > -1) || (item.newMessge.indexOf('.wav') > -1)) {
                  item.newMessge = '语音消息';
                }
                if ((item.newMessge.indexOf('png') > -1) || (item.newMessge.indexOf('jpg') > -1)) {
                  item.newMessge = '图片消息';
                }
              }
            })
          }
        }
      })
    },
    // 清除未读消息数量
    eliminateSpot(orderId) {
      this.$http('post', `/server/problemOrder/setUnread/${orderId}/1`, {}, 1).then(res => {
        // console.log('清除未读消息数量====》', res);
      })
    },
    onFastDetail(item) {
      if (item.orderStatus == 5) return;  //取消状态不可以进去
      // console.log(item);
      if (item.doctorBelong == 1) { // 药极客订单
        if (item.doctorBelong == 1 && item.orderStatus > 1) {
          if (item.unreadCountMember) { //有未读消息时，点击清除
            this.eliminateSpot(item.orderId)
          }
          this.$router.push({ path: '/dev_chating', query: { isShowBtn: true, problemId: item.orderId, doctorId: item.doctorId, orderId: item.orderId, orderType: item.orderStatus, templateType: item.templateType } })
        }
        if (item.orderPayStatus == 1 && item.templateType == 1) {  //快速问诊待支付
          this.$router.push({ path: '/fast_order', query: { orderId: item.orderId } })
        }
        if (item.orderPayStatus == 1 && item.templateType == 2) {//定向待支付
          if (item.consultType == 1) {
            this.$store.state.docType = item.doctorBelong;
            this.$router.push({ path: '/directional_order', query: { orderId: item.orderId } })
          }
        }
      } else { //春雨订单
        if (item.orderStatus !== 0) {
          if (item.consultType == 1) {   //consultType 1为图文  2为电话
            let number = 20;
            if (item.templateType == 2) {  //templateType 1为快速  2为定向
              number = 50
            } else {
              number = 20
            }
            this.$router.push({ path: '/chating', query: {orderId: item.orderId, isShowBtn: true, problemId: item.problemId, doctorId: item.doctorId, number: number } })
          } else {
            this.$router.replace({ path: '/tel_wait', query: { orderId: item.orderId } })
          }
        }
        if (item.orderPayStatus == 0 && item.templateType == 1) {  //快速问诊待支付
          this.$router.push({ path: '/fast_order', query: { orderId: item.orderId } })
        }
        if (item.orderPayStatus == 1 && item.templateType == 2) { // 定向
          if (item.consultType == 1) { // 图文
            this.$store.state.docType = item.doctorBelong;
            this.$router.push({ path: '/directional_order', query: { orderId: item.orderId } })
          }
          if (item.consultType == 2) { // 电话
            this.$router.push({ path: '/phone_order', query: { orderId: item.orderId } })
          }
        }
      }
    },
    formatOrderStatus(type) {
      return {
        0: '待支付',
        1: '待接单',
        2: '已接单',
        3: '问诊中',
        4: '已关闭',
        5: '已取消',
        6: '已拒绝',
        7: '被举报'
      }[type]
    },
    formatServiceOrderStatus(type) {
      /*  0: '待支付',
        1: '待接单',
        2: '已接单',
        3: '问诊中',
        4: '已关闭',
        5: '已取消',
        6: '已拒绝',
        7: '被举报' */
      return {
        's': '已预约',
        'd': '电话医生拒绝',
        't': '电话通话成功',
        'o': '电话连接失败',
        'ca': '电话已取消',
      }[type]
    },
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkitflex;
  align-items: center;
  -webkit-align-items: center;
}
.ds_flex2 {
  display: flex;
  display: -webkitflex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}
.list_fast {
  padding: 0.16rem 0.24rem;

  .item {
    // display: flex;
    // display: -webkitflex;
    // align-items: center;
    // -webkit-align-items: center;
    padding: 0.3rem 0.26rem;
    margin-bottom: 0.16rem;
    border-radius: 4px;
    background: #fff;
    .avatar {
      margin-right: 0.18rem;
      width: 1.02rem;
      height: 1.02rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .icon_hd::before {
      content: "";
      position: absolute;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: red;
      top: 0.18rem;
      left: 1.1rem;
    }
    .detail {
      width: calc(100% - 1.2rem);
    }
    .top {
      margin-bottom: 0.36rem;
      .type {
        margin-right: 0.16rem;
        font-size: 0.3rem;
        color: #333333;
      }
      .date {
        flex: 1;
        -webkit-flex: 1;
        font-size: 0.22rem;
        color: #999;
      }
      .state {
        font-size: 0.3rem;
        color: #ffb500;
      }
    }
    .bottom {
      font-size: 0.24rem;
      color: #666666;
      .tip {
        word-break: break-all;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.list {
  padding: 0 0.26rem 0.16rem;
  .item {
    padding: 0.16rem 0.24rem 0.28rem;
    margin-bottom: 0.16rem;
    border-radius: 4px;
    background: #fff;
    .top {
      padding-bottom: 0.14rem;
      margin-bottom: 0.28rem;
      border-bottom: 2px solid #f2f2f2;
      .icon {
        margin-right: 0.2rem;
        width: 0.3rem;
        height: 0.3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .is_phone {
        margin-right: 0.2rem;
        width: 0.34rem;
        height: 0.25rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        flex: 1;
        -webkit-flex: 1;
        font-size: 0.28rem;
        color: #333333;
      }
      .txt2 {
        font-size: 0.3rem;
        color: #ffb500;
      }
    }
    .bottom {
      .avatar {
        margin-right: 0.2rem;
        width: 1.02rem;
        height: 1.02rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .detail {
        width: calc(100% - 1.24rem);
      }
      .info {
        margin-bottom: 0.36rem;
        .name {
          margin-right: 0.16rem;
          font-size: 0.3rem;
          color: #333333;
        }
        .keshi {
          margin-right: 0.26rem;
          font-size: 0.26rem;
          color: #666666;
        }
        .date {
          font-size: 0.22rem;
          color: #999999;
        }
      }
      .tip {
        font-size: 0.24rem;
        color: #666666;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.consult {
  min-height: calc(100% - 20px);
  background: #f8f8f8;
  padding-bottom: 1.86rem;
  // padding-top: 1.86rem;
}
// .header {
//   /deep/ .van-tabs__wrap {
//   }
//   .list {
//     padding: 0.16rem 0.22rem;
//     width: calc(100% - 0.44rem);
//     .item {
//       display: flex;
//       align-items: center;
//       padding: 0.16rem 0.24rem;
//       width: calc(100% - 0.48rem);
//       background: #fff;
//       border-radius: 2px;
//       margin-bottom: 0.2rem;
//       img {
//         width: 1.22rem;
//         height: 1.22rem;
//         border-radius: 50%;
//         margin-right: 0.2rem;
//       }
//       .info {
//         width: calc(100% - 1.42rem);
//         .name {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin-bottom: 0.1rem;
//           p:nth-child(1) {
//             span:nth-child(1) {
//               font-size: 0.3rem;
//               color: #333;
//               margin-right: 0.32rem;
//             }
//             span:nth-child(2) {
//               font-size: 0.2rem;
//               color: #999;
//             }
//           }
//           p:nth-child(2) {
//             font-size: 0.24rem;
//             color: #ffc025;
//           }
//         }
//         .type {
//           margin-bottom: 0.16rem;
//           font-size: 0.26rem;
//           color: #333;
//           span:nth-child(1) {
//             margin-right: 0.1rem;
//           }
//         }
//         .describe {
//           font-size: 24rpx;
//           color: #999999;
//           width: 100%;
//           white-space: nowrap;
//           overflow: hidden;
//           display: inline-block;
//           text-overflow: ellipsis;
//         }
//       }
//     }
//   }
// }
.van_tab {
  position: fixed;
  width: 100%;
  color: #4a4a4a;
  z-index: 9;
  /deep/ .van-tab__text {
    font-size: 0.28rem;
  }
  /deep/ .van-tab--active {
    color: #3798ee;
    font-size: 0.32rem;
    font-weight: bold;
  }
  /deep/ .van-tabs__line {
    background-color: #3798ee;
  }
}
.van_list {
  padding-top: 1rem;
}
.new_list {
  margin: 0 0.22rem;
  background: #fff;
  padding: 0.2rem 0.3rem;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
  position: relative;
  .infos {
    display: flex;
    align-items: center;
    margin-bottom: 0.14rem;
    .avatar {
      width: 0.48rem;
      height: 0.48rem;
      margin-right: 0.16rem;
      border-radius: 50%;
    }
    .t1 {
      color: #333333;
      font-size: 0.28rem;
      margin-right: 0.12rem;
    }
    .t2 {
      color: #333333;
      font-size: 0.24rem;
      flex: 1;
    }
    .t3 {
      font-size: 0.24rem;
      color: #3798ee;
    }
    .spot {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background: red;
      // margin-left: 0.1rem;
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
    }
  }
  .cont {
    background: #f5f6f8;
    border-radius: 0.06rem;
    padding: 0.24rem;
    margin-bottom: 0.2rem;
    .t1 {
      margin-bottom: 0.2rem;
      font-size: 0.24rem;
      color: #333333;
      word-break: break-word;
    }
    .t2 {
      font-size: 0.24rem;
      color: #999999;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .t1 {
      flex: 1;
      color: #ffa438;
      font-size: 0.26rem;
    }
    .butn {
      width: 1.42rem;
      height: 0.52rem;
      // line-height: .52rem;
      color: #42cec8;
      border-radius: 100px;
      border: 1px solid #42cec8;
      text-align: center;
      font-size: 0.26rem;
      background: #fff;
      margin-right: 0.18rem;
    }
    .y {
      color: #ffa438;
      border: 1px solid #ffa438;
    }
    .n_r {
      margin-right: 0;
    }
  }
}
</style>
